.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modalContent {
	background: white;
	padding: 20px;
	border-radius: 10px;
	position: relative;
	text-align: center;
}

.modalHeader {
	display: flex;
	justify-content: space-between; /* 공지와 x 버튼 사이에 공간을 고르게 분배 */
	align-items: center;
}

.closeButton {
	position: absolute;
	top: 10px;
	right: 10px;
	background: none;
	border: none;
	font-size: 24px;
	cursor: pointer;
}

.imageContainer {
	height: 80vh; /* 화면 세로 크기의 70% */
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden; /* 이미지가 컨테이너를 벗어나지 않도록 설정 */
}

.announcementImage {
	max-height: 100%; /* 컨테이너 높이에 맞춤 */
	max-width: 100%; /* 컨테이너 너비에 맞춤 */
	height: auto;
	width: auto;
}
